.section {
  right: 10px;
  bottom: 15px;
  padding: 10px 12px;
  position: fixed;
  z-index: 1000;
  border: 1px solid white;
  border-radius: 50%;
  font-weight: 400;
  color: white;
  background-color: #00bd97;
  cursor: pointer;

  svg {
    width: 30px;
    height: 30px;
    margin-top: 2px;
  }
}

.container:link,
.container:visited,
.container:hover,
.container:active {
  color: white;
}

@media only screen and (min-width: 768px) {
  .section {
    bottom: 15px;
  }
}
