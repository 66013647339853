.section {
  width: 320px;
  height: 470px;
  margin: 0;
  right: 0;
  bottom: 110px;
  padding: 0;
  position: fixed;
  z-index: 1000;
  border-radius: 5px;
  font-weight: 400;
  color: #000;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
}

.icon {
  width: 50px;
  height: 50px;
  display: flex;
}

.header {
  width: 320px;
  height: 180px;
  margin: 0;
  padding: 12px 17px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 4px solid #00bd97;
  border-radius: 5px 5px 0 0;
  color: #fff;
  background-color: #00bd97;

  h2 {
    margin: 0 15px;
    display: flex;
    justify-content: flex-start;
    font-size: 1.5rem;
    font-weight: 500;
    color: #fff;
  }

  span {
    margin: 0 15px;
    display: flex;
    justify-content: flex-start;
    font-size: 15px;
  }
}

.text {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.container {
  margin: 0;
  padding: 12px 17px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: #fff;
  background-color: #fff;
}

.item {
  margin: 5px 0;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border: none;
  border-left: 2px solid #00bd97;
  border-radius: 5px;
  background-color: #f1f1f1;
  cursor: pointer;

  h3 {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 1.2rem;
    color: #333;
  }

  span {
    width: 90%;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 15px;
    color: #625f5f;
  }
}

.whatsapp {
  width: 45px;
  height: 45px;
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
  color: #00bd97;
}

@media only screen and (min-width: 768px) {
  .section {
    width: 380px;
    right: 10px;
    bottom: 87px;
  }

  .header {
    width: 380px;
    height: 140px;
  }
}
