.section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: inherit;
  font-family: Barlow, sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: start;
  color: white;
  background-color: #0dba97;
  cursor: pointer;
  box-shadow: 0 0 8px 5px rgb(0 0 0 / 20%);

  &:active {
    background: #00b08d;
    box-shadow: none;
    outline: none;
    transform: scale(0.95);
  }

  span {
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    width: 30px;
    height: 30px;
    margin-left: 1rem;
  }
}
