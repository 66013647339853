.section {
  width: 100%;
  padding: 0 2vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  border-top: 1px solid gray;
  font-family: Barlow, sans-serif;
  color: #353e52;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  h2 {
    margin-bottom: 10px;
    font-size: 19px;
    font-weight: 500;
    text-decoration-line: none;
    cursor: default;
  }

  h3,
  h4 {
    font-weight: 400;
    text-align: center;
  }

  h3 {
    font-size: 18px;
    text-decoration-line: underline;
    cursor: pointer;
  }

  h4 {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 14px;
  }

  a {
    padding-bottom: 3px;
    position: relative;
    font-size: clamp(0.8rem, 1vw, 1.5vw);
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    color: rgb(53 62 82 / 86%);
  }

  p {
    max-width: 900px;
    font-size: clamp(0.8rem, 1vw, 1.5vw);
    font-weight: 400;
    text-align: center;
    color: #929292;
  }

  h3:hover,
  a:hover {
    color: #00bd97;
  }

  .block1 {
    margin-top: 6vh;

    div {
      display: grid;
      grid-template-rows: repeat(8, 1fr);
      grid-template-columns: repeat(2, 1fr);

      a {
        margin-right: 1rem;
      }

      @media only screen and (max-width: 768px) {
        height: fit-content;
        margin-bottom: 0;
        padding-bottom: 0;
        grid-row-gap: 0;
      }
    }
  }

  .block2 {
    height: fit-content;
    margin-top: 6vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div:nth-child(1) {
      width: 150px;
    }

    div:nth-child(3) {
      display: flex;
      flex-wrap: nowrap;

      a {
        width: 40px;
        height: 40px;
        margin: 10px;
        padding-top: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: #fff;
        background: #353e52;
      }
    }

    @media only screen and (max-width: 768px) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .block3 {
    max-width: 375px;
    margin-top: 6vh;

    @media only screen and (max-width: 768px) {
      margin-top: 0;
      padding: 3vh;
    }
  }

  .block4 {
    margin-top: 4vh;
    padding: 60px 20px;
    border-top: 1px solid rgb(128 128 128 / 61.8%);

    @media only screen and (max-width: 768px) {
      padding-top: 30px;
    }
  }
}
