.header {
  width: 100%;
  height: fit-content;
  padding: 15px;
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: center;
  font-family: Barlow, sans-serif;
  background: linear-gradient(
    90deg,
    rgb(0 0 0 / 77.6%) 0%,
    rgb(0 0 0 / 57.6%) 30%,
    rgb(0 0 0 / 53.7%) 50%,
    rgb(0 0 0 / 57.3%) 70%,
    rgb(0 0 0 / 77.8%) 100%
  );

  .logo {
    margin: 0 20px;
    border: none;
    text-align: center;
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }
  }

  nav {
    max-width: 100%;
    min-width: 80%;
    right: 0;
    display: flex;

    ul {
      width: 100% !important;
      margin-left: 20px;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      list-style: none;

      li {
        width: fit-content;
        position: relative;
        display: block;
        border: none;
        font-family: Barlow, sans-serif;
        font-size: 1rem;
        line-height: 40px;
        text-decoration: none;
        background-color: transparent;
        cursor: pointer;

        button {
          border: none;
          font-size: 1.2rem;
          color: white;
          background-color: transparent;
        }

        div {
          width: fit-content;
          padding: 0 10px;
          display: block;
          text-decoration: none;
          color: #fff;
        }

        ul {
          width: fit-content !important;
          padding: 0;
          display: none;
          background: linear-gradient(
            90deg,
            rgb(0 0 0 / 77.6%) 0%,
            rgb(0 0 0 / 57.6%) 30%,
            rgb(0 0 0 / 53.7%) 50%,
            rgb(0 0 0 / 57.3%) 70%,
            rgb(0 0 0 / 77.8%) 100%
          );

          li {
            width: 100%;
            margin: 0;

            div {
              width: 100%;
              margin: 0;
              padding: 0 10px;
              display: flex;
              white-space: nowrap;
            }
          }
        }

        &.open > ul {
          display: block;
        }

        &:hover > ul,
        &:focus-within > ul {
          display: block;
        }

        div:hover,
        div:focus {
          background-color: #00bd97;
        }
      }
    }

    .buttonContact {
      width: 150px;
      height: 40px;
      margin: 0 20px;
      padding: 0 20px;
      border: none;
      border-radius: 10px;
      font-family: Barlow, sans-serif;
      font-size: 1rem;
      font-weight: 400;
      white-space: nowrap;
      text-align: center;
      color: #fff;
      background-color: #00bd97;
      cursor: pointer;
    }
  }
}

// ### LARGE SIZE ###

@media (min-width: 768px) {
  .header {
    justify-content: space-between;

    .toggle,
    .apVenda {
      display: none;
    }

    nav {
      display: flex !important;
      justify-content: space-between;

      ul {
        width: 100%;
        padding-left: 5%;
        justify-content: center;
        gap: 0.5rem;

        li:hover > ul,
        li:focus-within > ul {
          top: 100%;
          left: 0;
          position: absolute;
          z-index: 1;
          display: block;
        }

        .close {
          display: none;
        }
      }
    }

    .areaClickClose {
      display: none !important;
    }
  }
}

// ### MOBILE SIZE ###

@media (max-width: 768px) {
  .header {
    width: 100%;
    padding: 15px 5%;
    position: fixed;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    font-family: Barlow, sans-serif;
    background: linear-gradient(
      90deg,
      rgb(0 0 0 / 77.6%) 0%,
      rgb(0 0 0 / 57.6%) 30%,
      rgb(0 0 0 / 53.7%) 50%,
      rgb(0 0 0 / 57.3%) 70%,
      rgb(0 0 0 / 77.8%) 100%
    );

    ::-webkit-scrollbar {
      width: 3px;
    }

    ::-webkit-scrollbar-thumb {
      background: #00bd97;

      &:hover,
      &:active {
        background: #00bd97;
      }
    }

    .logo {
      width: 85px;
      margin: 0;
    }

    .apVenda {
      padding: 8px;
      display: flex;
      align-items: center;
      border: 1px solid white;
      border-radius: 8px;
      font-weight: 600;
      color: white;
      background-color: transparent;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
        margin-top: 2px;
      }
    }

    .toggle {
      padding: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;
      border: none;
      border-radius: 5px;
      line-height: 5px;
      background-color: transparent !important;

      &:hover {
        cursor: pointer;
      }

      span {
        width: 25px;
        height: 3px;
        border-radius: 5px;
        background-color: rgb(212 212 212);
      }
    }

    nav {
      width: fit-content;
      max-width: 80vw;
      height: 100%;
      max-height: 100vh;
      top: 0;
      padding-bottom: 4vh;
      position: relative;
      z-index: 999;
      display: none;
      backdrop-filter: blur(3px);
      overflow-x: hidden;
      background: linear-gradient(
        90deg,
        rgb(0 0 0 / 77.6%) 0%,
        rgb(0 0 0 / 57.6%) 30%,
        rgb(0 0 0 / 53.7%) 50%,
        rgb(0 0 0 / 57.3%) 70%,
        rgb(0 0 0 / 77.8%) 100%
      );

      ul {
        width: fit-content;
        height: fit-content;
        margin: 0;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        list-style: none;

        li {
          margin: 10px;
          border-radius: 2px;
          font-weight: bold;
          color: white;

          ul {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            background: transparent;

            li {
              font-weight: 300;
            }
          }
        }

        .close {
          width: 100%;
          margin: 0;
          display: flex;
          justify-content: flex-end;
          font-size: 1.5rem;
          font-weight: bold;
          text-align: right;
          color: white;
          cursor: default;

          button:hover {
            cursor: pointer;
          }
        }
      }

      .buttonContact {
        margin-left: 30px;
      }

      /* Opening hamburger menu!
    - Using `:target` for non-JavaScript
    - `[aria-expanded]` will be used if/when JavaScript is added to improve interaction, though it's completely optional. */
      nav:target,
      nav[aria-expanded='true'] {
        left: 0;
        display: block;
        outline: none;
      }
    }

    /* Allow backdrop to stay fixed and not "scroll" */

    @supports (position: fixed) {
      nav,
      nav:target + .backdrop,
      nav[aria-expanded='true'] + .backdrop {
        position: fixed;
      }
    }

    .areaClickClose {
      width: 70vw;
      height: 200vh;
      margin-right: 30vw;
      margin-left: -50px;
      position: absolute;
      z-index: 998;
    }
  }
}
