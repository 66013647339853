@font-face {
  font-family: Barlow;
  src: url('/fonts/Barlow/Barlow-Medium.ttf');
  font-display: block;
}

@font-face {
  font-family: Khand;
  src: url('/fonts/Khand/Khand-Medium.ttf');
  font-display: block;
}

html {
  font-size: 110%;
} /* 16px */

$color-primary: #00bf98;
$color-secondary: #353e52;
$color-light: #f1f1f1;
$color-white: #fff;
$font-primary: khand, sans-serif;
$font-secondary: barlow, sans-serif;

* {
  margin: 0;
  padding: 0;
  font-family: $font-secondary, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0 0 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $color-secondary;
}

h1 {
  font-family: $font-primary;
  font-size: 3.052rem;
}

h2 {
  margin-bottom: 2rem;
  font-family: $font-primary;
  font-size: 2.441rem;
}

h3 {
  font-family: $font-secondary;
  font-size: 1.953rem;
}

h4 {
  font-family: $font-secondary;
  font-size: 1.563rem;
}

h5 {
  font-family: $font-secondary;
  font-size: 1.25rem;
}

p {
  font-family: $font-secondary;
  font-size: 1rem;
}

a {
  font-size: 1rem;
  text-decoration-line: none;
  color: $color-primary;
  cursor: pointer;
}

figcaption {
  display: none;
}

// https://github.com/vercel/next.js/issues/10142
