.FloatingContainer {
  bottom: 2rem;
  left: 1.5rem;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: bold;
  font-weight: 500;
}

.hidden {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.visible {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
