.bottomBar {
  width: 100%;
  bottom: 0;
  position: fixed;
  z-index: 1;
  display: flex;
  background-color: gray;
  box-shadow: 0 0 8px 5px rgb(0 0 0 / 20%);
}

.hidden {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.visible {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
